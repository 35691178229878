import axios from 'axios';
import GBLVAR from '../GlobalVariables/globalvaribles';


class ApiRequests {  
  static getAccess(URLTYPE,header){
    return axios({method:'get',url:GBLVAR.API_URL + URLTYPE,headers:header})
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status == 422) {
          //window.location.href = '/notfound';
        return error.response;
      }
    });
  }

  static currentCountry(URLTYPE){
    return axios({method:'get',url:URLTYPE})
    .then(function (response) {
      if(response.status==200){
        return response.data;
      }  
      else{
        return {
          countryCode : 'AE'
        }
      }   
    })
    .catch(function (error) {
      return {
        countryCode : 'AE'
      }
    });
  }

  static postValues(URLTYPE,header,params) {    
    return axios({method:'POST',url:GBLVAR.API_URL + URLTYPE,headers:header,data: params})
    .then(function (response) {
      if (response.status === 200){
        return response.data;
      }  
      if (response.status === 422){
        return response.message
      }  
    })
    .catch(function (error) {
      return error;
    });
  }

  static postHValues(URLTYPE,header,params){     
    return axios({method:'POST',url:URLTYPE,headers:header,data:params})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if(error.response.status === 422){
        return error.response.status;
      }
      else{
        return error.response.data;
      } 
    });
  }
}
  export default ApiRequests;  