import React, { Component } from 'react';
import Footer from '../Components/Footer';
import { connect } from 'react-redux';
import Splashscreen from '../Components/Splashscreen';
import Home from '../Components/Home';
import Success from '../Components/Success';
import Cancel from '../Components/Cancel';
import Directsuccess from '../Components/Directsuccess';
import Directcancel from '../Components/Directcancel';
import CardCancel from '../Components/CardCancel';
import C2PCardCancel from '../Components/C2PCardCancel';
import SamsungCancel from '../Components/SamsungCancel';
import SamsungPay from '../Components/SamsungPay';
import {imagePath} from '../actions/ImagePath';
import { APP_LOAD } from '../constants/actionTypes';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { Route, Switch,BrowserRouter as Router } from 'react-router-dom';
import Message from '../Components/message';

class App extends Component {
  componentDidMount() {
    var url = window.location.search;
		var query = url.replace("?", '').replace(/%22/g, '"');
    try {
      var querydata = JSON.parse(query);
      if(querydata.align){
        document.getElementById("fl_pay_center_wraper").style.alignItems = querydata.align;
      }
    }
    catch{
    }
    setTimeout(() =>
      this.props.onLoad()
    ,1000);
  }
  render() {   
    if (!this.props.loading) {
      return (
        <div id="fl_pay_center_wraper" className="fl_pay_center_wraper">                
          <div className="fl_pay_wraper"> 
            <Switch>
              <Route exact={true} path={GBLVAR.PAGE_NAME.Root} component = {Splashscreen} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Home} component = {Home} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Success} component = {Success} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Cancel} component = {Cancel} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.LinkPaid} component = {Message} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Directsuccess} component = {Directsuccess} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Directcancel} component = {Directcancel} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.CardCancel} component = {CardCancel} /> 
              <Route exact={true} path={GBLVAR.PAGE_NAME.C2PCardCancel} component = {C2PCardCancel} /> 
              <Route exact={true} path={GBLVAR.PAGE_NAME.SamsungCancel} component = {SamsungCancel} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.SamsungPay} component = {SamsungPay} />
              
            </Switch>
            {window.location.pathname.split('/')[1] == 'success' || window.location.pathname.split('/')[1] == 'cancel' ? null :
            <Footer />
            }
            <div id="error_log" className="error_log"></div>
          </div>
        </div>
      );
    } else{
      return (
        <div id="fl_pay_center_wraper" className="fl_pay_center_wraper">                
          <div className="fl_pay_wraper"> 
            {window.location.pathname.split('/')[1] == 'success' || window.location.pathname.split('/')[1] == 'cancel' ? null :
            <>
            <div className="fl_pay_section loader_wrap text-center">  
              <div className="loaderIcon">
                <img className="loaderImage" src={imagePath('./loader.gif')} alt=""/>
                <p className="loading_text_contFirst">Loading... </p>
                <p className="loading_text_cont"> Kindly DO NOT close, refresh or click back button.</p>
              </div>	
            </div>
            <Footer />
            </>
            }
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    loading : state.AppReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch({ type: APP_LOAD }),
  onDataLoad: () => dispatch({ type: APP_LOAD })
});

export default connect(mapStateToProps, mapDispatchToProps)(App);