import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import 'url-search-params-polyfill';
import axios from 'axios';
class Directcancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        reference_id:'',
        payment_transaction_id:'',
        transaction_status:""
      }
      this.paymentComplete = this.paymentComplete.bind(this);
    }
    paymentComplete(){
        var _this=this;
        var returnUrl = _this.state.site_return_url;
        if(returnUrl !== ''){
            var form = document.createElement("form");
            form.setAttribute("method", "POST");
            form.setAttribute("action", returnUrl);
            form.setAttribute("enctype", "application/json");
            var statusInput = document.createElement("input"); 
            statusInput.setAttribute("type", "hidden"); 
            statusInput.setAttribute("name", "status"); 
            statusInput.setAttribute("id", "status"); 
            statusInput.setAttribute("value", "error");
            var transactionNoInput = document.createElement("input"); 
            transactionNoInput.setAttribute("type", "hidden"); 
            transactionNoInput.setAttribute("name", "transaction_no"); 
            transactionNoInput.setAttribute("id", "transaction_no"); 
            transactionNoInput.setAttribute("value", _this.state.transaction_no);
            var foloosiInput = document.createElement("input"); 
            foloosiInput.setAttribute("type", "hidden"); 
            foloosiInput.setAttribute("name", "foloosi_payment_id"); 
            foloosiInput.setAttribute("id", "foloosi_payment_id"); 
            foloosiInput.setAttribute("value", _this.state.transaction_no);      
            var foloosiOption1Input = document.createElement("input"); 
            foloosiOption1Input.setAttribute("type", "hidden"); 
            foloosiOption1Input.setAttribute("name", "optional1"); 
            foloosiOption1Input.setAttribute("id", "optional1"); 
            foloosiOption1Input.setAttribute("value", _this.state.optional1);
            var foloosiOption2Input = document.createElement("input"); 
            foloosiOption2Input.setAttribute("type", "hidden"); 
            foloosiOption2Input.setAttribute("name", "optional2"); 
            foloosiOption2Input.setAttribute("id", "optional2"); 
            foloosiOption2Input.setAttribute("value", _this.state.optional2);
            var foloosiOption3Input = document.createElement("input"); 
            foloosiOption3Input.setAttribute("type", "hidden"); 
            foloosiOption3Input.setAttribute("name", "optional3"); 
            foloosiOption3Input.setAttribute("id", "optional3"); 
            foloosiOption3Input.setAttribute("value", _this.state.optional3);        
            if(_this.state.subscription_no){
                var foloosiSubNoInput = document.createElement("input"); 
                foloosiSubNoInput.setAttribute("type", "hidden"); 
                foloosiSubNoInput.setAttribute("name", "subscription_no"); 
                foloosiSubNoInput.setAttribute("id", "subscription_no"); 
                foloosiSubNoInput.setAttribute("value", _this.state.subscription_no);
                form.appendChild(foloosiSubNoInput); 
            }      
            form.appendChild(statusInput); 
            form.appendChild(transactionNoInput); 
            form.appendChild(foloosiInput); 
            form.appendChild(foloosiOption1Input); 
            form.appendChild(foloosiOption2Input); 
            form.appendChild(foloosiOption3Input); 
            document.body.appendChild(form);
            form.submit();
        }else{
            setTimeout(() => {
                var data = {
                    "payment_status": "Transaction Cancelled",
                    "transaction_no": _this.state.transaction_no,
                    "amount": _this.state.amount,
                    "currency": _this.state.currency,
                    "site_return_url": _this.state.site_return_url,
                    "optional1": _this.state.optional1,
                    "optional2": _this.state.optional2,
                    "optional3": _this.state.optional3
                }
                var dataSuccess = {"status":"error","data": data}
                window.top.parent.postMessage(dataSuccess, "*")
                if(window.ReactNativeWebView !== undefined){
                    try{
                        window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess));
                    }
                    catch(error){
                        window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess), '*');
                    }
                }
            }, 2000);   
        }  
    }
    componentDidMount() {	
        var _this=this;
        if(_this.props.App_Details.merchant !== undefined && _this.props.App_Details.custom_design_applicable == 1){
            document.documentElement.style.setProperty('--main-theme-color',_this.props.App_Details.merchant.theme_color);
            document.documentElement.style.setProperty('--main-primary-color',_this.props.App_Details.merchant.primary_color);
        }
        var _this=this;
        var urlParams = new URLSearchParams(window.location.search);
        _this.setState({
            reference_id:urlParams.get('refid'),transaction_no:urlParams.get('transaction_no'),currency:urlParams.get('currency'),amount:urlParams.get('amount'),site_return_url:urlParams.get('site_return_url'),optional1:urlParams.get('optional1'),optional2:urlParams.get('optional2'),optional3:urlParams.get('optional3'),transaction_status:urlParams.get('transaction_status')
        },()=>{
            _this.paymentComplete();
        })
    }
    render() {
        return (
            <>
            <Header />
            <div className="fl_pay_section text-center">
                <div className="status_heading">{this.state.transaction_status == "review" ? "Payment in Progress" : "Payment Cancelled!"}</div>
                <div className="status_heading">Please wait to be redirected.</div>
                {this.props.App_Details.hide_foloosi_reference == 0 && 
                    <div className="status_trans_id">Payment ID - #{this.state.transaction_no}</div>
                    }
                <div className="status_trans_icon">
                    {this.state.transaction_status == "review" ? 
                    <img src={imagePath('./pending.svg')} />
                    :
                    <img src={imagePath('./cancel.svg')} />
                    }                    
                </div>
                {this.state.transaction_status !== "review" &&
                <div className="status_trans_message">Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.</div>
                }
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
	return {
		state : state.GuestReducer,
		App_Details : state.AppReducer.App_Details
	}
};

const mapDispatchToProps = dispatch => ({ 

})
          
export default connect(mapStateToProps, mapDispatchToProps)(Directcancel);
