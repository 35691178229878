import React, { Component } from 'react';
import {imagePath} from '../actions/ImagePath';

class Loader extends Component {
    render() {
        return (
            <div className="loaderIcon">   
                <img className="loaderImage" src={imagePath('./loader.gif')} alt=""/>
                <p className="loading_text_contFirst">Loading... </p>
                <p className="loading_text_cont"> Kindly DO NOT close, refresh or click back button.</p>
            </div>
        );
    }
}
          
export default Loader;