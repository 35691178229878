
var urlType = true; 
if (urlType) {
module.exports = {
    PAGE_NAME:{
    	Root : '/',
        Home : '/home',
        Success : '/success',
        Cancel : '/cancel',
        Decline : '/decline',
        Directsuccess:'/directsuccess',
        Directcancel:'/directcancel',
        CardCancel:'/card-cancel',
        C2PCardCancel:'/payment-cancel',
        SamsungCancel:'/samsungpay-cancel',
        SamsungPay:'/samsung-pay',
        LinkPaid:'/notfound'
    },
    //USER_URL:"http://localhost:3002",
    USER_URL:"https://widget.foloosi.com",
    // USER_URL:"https://order.foloosi.com",
    BASE_USER_TEST_API_URL: "https://foloosi.com/api/store",
    API_URL_NEW:'https://foloosi.com/api/v1/api/',
    APPLEPAY_URL:'https://foloosi.com/api/v1/',
    /* CHARGE_REQUEST_URL:'https://flstrans.foloosi.com/aggregatorapi/web/payment-request',   
    API_URL:"https://flstrans.foloosi.com/aggregatorapi/web/",*/
    SAMSUNG_PAY: "https://api.foloosi.com/aggregator/web/spay/order/create",
    GET_SAMSUNG_TOKEN: "https://api.foloosi.com/aggregator/web/spay/payment/data",
    // CHARGE_REQUEST_URL:'https://api.foloosi.com/aggregatorapi/web/payment-request',
    CHARGE_REQUEST_URL:'https://api.foloosi.com/aggregatorapi/web/payment-request',
    API_URL:"https://api.foloosi.com/aggregatorapi/web/",
    /* USER_URL:"https://widget.roamsoft.co",
    BASE_USER_TEST_API_URL: "https://roamsoft.co:8443/store",    
    API_URL:'https://roamsoft.co:8443/v1/api/',
    APPLEPAY_URL:'https://roamsoft.co:8443/api/v1/',
    CHARGE_REQUEST_URL:'https://roamsoft.co:8443/v1/payment/charge-request', */
    GET_COUNTRY : 'https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ',
    API_LIST: {
        API_ACCESS : 'get-access-request',
        GUEST_SIGNUP : 'guest-signup',
        TRANSACTION : 'customer/initiate-transaction',
        COMPLETE_PAYMENT : 'customer/complete-payment',
    }
}
}else{
    module.exports = {
        PAGE_NAME:{
            Root : '/',
            Home : '/home',
            Success : '/success',
            Cancel : '/cancel',
            Decline : '/decline',
            Directsuccess:'/directsuccess',
            Directcancel:'/directcancel',
            CardCancel:'/card-cancel',
            C2PCardCancel:'/payment-cancel',
            SamsungCancel:'/samsungpay-cancel',
            SamsungPay:'/samsung-pay',
            LinkPaid:'/notfound'
        },
        // USER_URL:"http://localhost:3000",
        // USER_URL:"https://widget.foloosi.com",
        USER_URL:"https://order.foloosi.com",
        BASE_USER_TEST_API_URL: "https://foloosi.com/api/store",
        API_URL_NEW:'https://foloosi.com/api/v1/api/',
        APPLEPAY_URL:'https://foloosi.com/api/v1/',
        /* CHARGE_REQUEST_URL:'https://flstrans.foloosi.com/aggregatorapi/web/payment-request',   
        API_URL:"https://flstrans.foloosi.com/aggregatorapi/web/",*/
        SAMSUNG_PAY: "https://api.foloosi.com/aggregator/web/spay/order/create",
        GET_SAMSUNG_TOKEN: "https://api.foloosi.com/aggregator/web/spay/payment/data",
        // CHARGE_REQUEST_URL:'https://api.foloosi.com/aggregatorapi/web/payment-request',
        CHARGE_REQUEST_URL:'https://api.foloosi.com/sandbox/aggregatorapi/web/payment-request',
        API_URL:"https://api.foloosi.com/sandbox/aggregatorapi/web/",
        /* USER_URL:"https://widget.roamsoft.co",
        BASE_USER_TEST_API_URL: "https://roamsoft.co:8443/store",    
        API_URL:'https://roamsoft.co:8443/v1/api/',
        APPLEPAY_URL:'https://roamsoft.co:8443/api/v1/',
        CHARGE_REQUEST_URL:'https://roamsoft.co:8443/v1/payment/charge-request', */
        GET_COUNTRY : 'https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ',
        API_LIST: {
            API_ACCESS : 'get-access-request',
            GUEST_SIGNUP : 'guest-signup',
            TRANSACTION : 'customer/initiate-transaction',
            COMPLETE_PAYMENT : 'customer/complete-payment',
        }
    }
}