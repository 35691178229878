import React from 'react';
import ReactDOM from 'react-dom';
import './styles/css/font-awesome.css';
import './styles/css/bootstrap.min.css';
import './styles/css/flags.css';
import './styles/css/foloosi.css';
import App from './Container/App';
import { Provider } from 'react-redux';
import { store,history } from './store/configureStore';
import { Route, Switch,BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== "development")
  console.log = () => {};

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider> , document.getElementById('root')
);
serviceWorker.unregister();
