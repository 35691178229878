import { Countries } from '../Components/countries';

export function returnCountryInfo(code) {
  var coObj = Countries.map(function(e) { return e["code"]; }).indexOf(code);
  return Countries[coObj];
}

export function getInfo(detail) {
  var data = {
    country_code : detail.code,
    country_name : detail.name,
    country_flag : detail.flag,
    countryRegion : detail["2digit"]    
  }
  return data;
}

export function returnCodeInfo(code) {
  var coObj = Countries.map(function(e) { return e["2digit"] || e["3digit"]; }).indexOf(code);
  return Countries[coObj];
}