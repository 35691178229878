import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import GBLVAR from '../GlobalVariables/globalvaribles';

class C2PCardCancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{}
      }
    }
    goBack(){
        this.props.history.push(GBLVAR.PAGE_NAME.Home+'?refid='+this.props.App_Details.transaction_no);
    }
    componentDidMount() {
        if(this.props.App_Details.merchant !== undefined && this.props.App_Details.custom_design_applicable == 1){
            document.documentElement.style.setProperty('--main-theme-color',this.props.App_Details.merchant.theme_color);
            document.documentElement.style.setProperty('--main-primary-color',this.props.App_Details.merchant.primary_color);
        }
    }
    render() {
        return (
            <>
            <Header />
            <div className="fl_pay_section text-center">
                <div className="status_heading"> Payment Cancelled</div>
                <div className="status_trans_icon">   
                    <img src={imagePath('./cancel.svg')} />
                </div>
                <div className="status_trans_message_new">Note : Payment could not be processed due to some technical error or card not supported. Please try again.</div>
            </div>
            </>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		App_Details : state.AppReducer.App_Details
	}
};
          
export default connect(mapStateToProps)(C2PCardCancel);