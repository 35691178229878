export const APP_LOAD = 'APP_LOAD';
export const GET_ACCESS = 'GET_ACCESS';
export const SHOW_COUNTRY = 'SHOW_COUNTRY';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const GUEST_ONCHANGE = 'GUEST_ONCHANGE';
export const GET_COUNTRY = 'GET_COUNTRY';
export const FALSE_SCREEN = 'FALSE_SCREEN';
export const TRUE_SCREEN = 'TRUE_SCREEN';
export const PHONE_EMPTY = 'PHONE_EMPTY';
export const SET_NUMBER = 'SET_NUMBER';
export const GUEST_INFO = 'GUEST_INFO';   
export const GUEST_SIGNUP = 'GUEST_SIGNUP';   
export const INIT_TRANS = 'INIT_TRANS';   
export const PAYMENT_SCREEN = 'PAYMENT_SCREEN';  
export const UPDATE_PROPS = 'UPDATE_PROPS';  
export const SET_COUNTRY = 'SET_COUNTRY';  
export const TRIM_NAME = 'TRIM_NAME';  
export const CHECK_CHANGE = 'CHECK_CHANGE';  
export const CARD_CHANGE = 'CARD_CHANGE';  
export const ON_CHANGE = 'ON_CHANGE';
export const GET_COUNTRY_DETAILS = 'GET_COUNTRY_DETAILS';
export const COUNTRY_DETAIL = 'COUNTRY_DETAIL';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const GET_QUERYDATA = 'GET_QUERYDATA';