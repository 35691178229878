import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { GET_ACCESS, GET_QUERYDATA } from '../constants/actionTypes';
import 'url-search-params-polyfill';
import axios from 'axios';
class Message extends Component {
    constructor (props) {
        super(props);
        this.state = {
            
        }
    }
    componentDidMount() {
        console.log(this.props,"asa");
    }
    render() {
        return (
        <div className="width_100 text-center">
            <div className="status_trans_icon">
                <img src={imagePath('./cancel.svg')} /> 
                {/* <p className="link_paid_txt">Link Already Paid or Invalid Link </p> */}
                <p className="link_paid_txt">{this.props.App_Details?.data?.message}</p>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        App_Details : state.AppReducer.App_Details,
		queryDataObject: state.AppReducer.queryDataObject
	}
};

const mapDispatchToProps = dispatch => ({
	onLoad: (header) => dispatch({ type: GET_ACCESS, payload: dataservice.getAccess(GBLVAR.API_LIST.API_ACCESS,header) }),
	onDataLoad: (querydata) => dispatch({ type: GET_QUERYDATA, payload:querydata  })

});
          
export default connect(mapStateToProps, mapDispatchToProps)(Message);